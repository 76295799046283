<template>
  <div class="netMessage-detail-container">
    <navigator></navigator>
    <div style="width: 1200px;margin: auto;background-color: #fff;padding: 20px">
      <div class="form-item-div">
        <div class="form-item-label">活动主题</div>
        <el-input v-model="form.messageTitle" style="width: 300px"></el-input>
      </div>
      <div class="form-item-div" style="z-index: 99999">
        <div class="form-item-label">科室</div>
        <el-select style="margin-right: 20px" v-model="form.department" clearable placeholder="请选择科室">
          <el-option label="高中部" value="高中部"></el-option>
          <el-option label="课程部" value="课程部"></el-option>
          <el-option label="小学部" value="小学部"></el-option>
          <el-option label="德育部" value="德育部"></el-option>
          <el-option label="体音美部" value="体音美部"></el-option>
          <el-option label="师训部" value="师训部"></el-option>
          <el-option label="干训部" value="干训部"></el-option>
          <el-option label="教育学会" value="教育学会"></el-option>
          <el-option label="初中部" value="初中部"></el-option>
          <el-option label="教科研部" value="教科研部"></el-option>
          <el-option label="宣教部" value="宣教部"></el-option>
          <el-option label="办公室" value="办公室"></el-option>
          <el-option label="学前部" value="学前部"></el-option>
          <el-option label="教务处" value="教务处"></el-option>
          <el-option label="财务室" value="财务室"></el-option>
          <el-option label="技活职成部" value="技活职成部"></el-option>
          <el-option label="总务处" value="总务处"></el-option>
          <el-option label="工会委员会" value="工会委员会"></el-option>
        </el-select>
        <el-input v-model="form.departmentPart" style="width: 300px"></el-input>
        <!--<el-select v-model="value" placeholder="请选择">-->
          <!--<el-option-->
                  <!--v-for="item in options"-->
                  <!--:key="item.value"-->
                  <!--:label="item.label"-->
                  <!--:value="item.value">-->
          <!--</el-option>-->
        <!--</el-select>-->
      </div>
      <div class="form-item-div" v-show="userInfo.userType == 4">
        <div class="form-item-label">浮动窗口推送</div>
        <el-checkbox v-model="form.isFloat"></el-checkbox>
      </div>
      <div class="form-item-div">
        <div class="form-item-label">上传附件</div>
        <fileUpload @uploadFileSuccess="uploadFileSuccess" />
      </div>
      <div class="form-item-div">
        <div class="form-item-label">发送内容</div>
        <div id="editor"></div>
      </div>
      <div style="width: 100%;">
        <el-button type="primary" @click="confirmClick">发送</el-button>
        <el-button @click="$router.go(-1)">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import E from 'wangeditor'
  import fileUpload from '../../components/fileUpload'
  import navigator from '../userCenter/navigator'
  import cookieUtil from "@/util/cookieUtil"
  export default {
    name: "detail",
    components: {
      navigator,
      fileUpload
    },
    data() {
      return {
        form: {
          id: '',
          messageTitle: '',
          department: '',
          departmentPart: '',
          filesUrl: '',
          isFloat: false
        },
        options: [
          {
            label: '教务处',
            value: '1'
          }
        ],
        checked: false,
        editor: null,
        value: '',
        userInfo: {},
      }
    },
    mounted() {
      this.userInfo = JSON.parse(cookieUtil.getCookie('userInfo'))
      this.initEditor()
    },
    methods: {
      uploadFileSuccess(data) {
        let {fileList = []} = data
        let file_result = []
        fileList.forEach(item => {
          file_result.push(item.response.result)
        })
        this.form.filesUrl = file_result.join(',')
      },
      confirmClick() {
        let { messageTitle, department, departmentPart, isFloat, id, filesUrl } = this.form
        id ? this.$api.home.webMessageUpdate({
          id,
          messageTitle,
          department,
          departmentPart,
          isFloat: isFloat ? '1': '0',
          filesUrl,
          messageContent: this.editor.txt.html(),
          userId: JSON.parse(cookieUtil.getCookie('userInfo')).id
        }).then(res => {
          if (res.code == 200) {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
            this.$router.go(-1)
          }
        })
          :
          this.$api.home.webMessageCreate({
          messageTitle,
          department,
          departmentPart,
          filesUrl,
          isFloat: isFloat ? '1': '0',
          messageContent: this.editor.txt.html(),
          userId: JSON.parse(cookieUtil.getCookie('userInfo')).id
        }).then(res => {
          if (res.code == 200) {
            this.$message({
              message: '创建成功',
              type: 'success'
            })
            this.$router.go(-1)
          }
        })
      },
      initEditor() {
        this.editor = new E('#editor')
        this.editor.config.excludeMenus = [
          'head',
          'emoticon',
          'video',
          'code',
          'splitLine',
          'lineHeight',
          'todo',
          'redo'
        ]
        this.editor.config.uploadImgServer = '/tzapi/upload/putFile'
        this.editor.config.uploadFileName = 'multipartFile'
        this.editor.config.uploadImgHooks = {
          customInsert: function(insertImgFn, result) {
            insertImgFn(result.result)
          }
        }
        this.editor.config.fontSizes = {
          'x-small': { name: '10px', value: '1' },
          'small': { name: '13px', value: '2' },
          'normal': { name: '16px', value: '3' },
          'large': { name: '18px', value: '4' },
          'x-large': { name: '24px', value: '5' },
          'xx-large': { name: '32px', value: '6' },
          'xxx-large': { name: '48px', value: '7' },
        }
        this.editor.create()
        this.$route.params.id && this.selectWebMessageById()
      },
      selectWebMessageById() {
        this.$api.home.selectWebMessage({
          id: this.$route.params.id
        }).then(res => {
          let { code, result } = res
          if (code == 200) {
            let _obj = result.rows[0]
            _obj.isFloat = _obj.isFloat === '1'
            Object.assign(this.form, _obj)
            this.editor.txt.html(_obj.messageContent)
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  .w-e-menu {
    z-index: 2 !important;
  }
  .w-e-toolbar {
    z-index: 2 !important;
  }
  .w-e-text-container {
    z-index: 1 !important;
  }
.netMessage-detail-container {
  .form-item-div {
    width: 1000px;
    display: flex;
    padding: 15px 0;
    align-items: center;
    .form-item-label {
      width: 160px;
      text-align: left;
    }
  }
  #editor {
    width: 800px;
  }
}
  .w-e-toolbar p, .w-e-text-container p, .w-e-menu-panel p {
    font-size: 18px !important;
  }
</style>